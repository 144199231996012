
__webpack_public_path__ = `${document.querySelector("html").dataset.publicPath}/`;

class LazysizesConfig {
    init() {
        console.log('[Lazyload] Init configurations..');
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.lazyClass = 'lazyload';
        //page is optimized for fast onload event
        window.lazySizesConfig.loadMode = 1;

        console.log('[Lazyload] Initialized with config', window.lazySizesConfig);
    }
}

let lazysizesConfig = new LazysizesConfig();
lazysizesConfig.init(); // Overriding the configurations

import(/* webpackChunkName: "lazysizes" */ 'lazysizes');

